<template>
    <div>
        <Loading v-show="loadingActive"/>
        <InfoModal v-if="modalActive" :modal-message="modalMessage" v-on:close-modal="closeModal"/>
        <div class="container">
            <div class="row ">
                <div class="col-12 col-md-5 col-lg-5 my-5 pt-5 mx-auto">
                    <div class="card my-2 p-0 m-0">
                        <div class="card-body">
                            <div class="text-center">
                                <h1><i class="fa fa-user-lock text-warning"></i></h1>
<!--                                <button class="btn btn-outline-dark" @click="googleSignIn"><i class="fa fa-circle"></i> Sign in with google</button>-->
                                <p class="alert alert-warning" v-if="error" >
                                    {{this.errorMsg}}
                                </p>
                            </div>

                            <form>
                                <div class="col-12 ">
                                    <div class="form-floating mb-3">
                                        <input type="text" id="email" v-model="form.email" class="form-control" required placeholder="Enter Ms Code">
                                        <label for="email">Email</label>
                                    </div>

                                </div>
                                <div class="col-12 ">
                                    <div class="form-floating mb-3">
                                        <input type="password" id="password" v-model="form.password" class="form-control" required placeholder="Enter Ms Code">
                                        <label for="password">Password</label>
                                    </div>

                                </div>

                                <button class="mt-3 w-100 py-2 btn btn-warning text-white" @click.prevent="signIn">Sign in</button>
                            </form>

                            <!--<div class="text-center mt-3">
                                <router-link :to="{name:'ForgotPassword'}" class="nav-link">Forgot password?</router-link>
                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import firebase from "firebase/app";
    import "firebase/auth";
    import Loading from "@/components/Loading";
    import InfoModal from "@/components/InfoModal";
    export default {
        name:'Login',
        components: {InfoModal, Loading},
        data(){
            return{
                form:{
                    email:"",
                    password:"",
                },
                loadingActive:false,
                modalActive:false,
                modalMessage:"",
                error:null,
                errorMsg:"",
            }
        },
        methods:{
            async signIn(){
                this.loadingActive = true;
                if(this.form.email !== "" && this.form.password !==""){
                    await firebase.auth().signInWithEmailAndPassword(this.form.email, this.form.password)
                        .then(()=>{
                            this.loadingActive = false;
                            this.$router.push({name:"home"});
                        }).catch((err)=>{
                            this.loadingActive = false;
                            this.modalMessage=err.message;
                            this.modalActive = true;
                            this.form.password = "";
                            /*this.error= true;
                            this.errorMsg = err.message;
                            setTimeout(()=>{
                                this.error = false;
                            }, 5000);
                            return;*/
                        });
                    return;
                }else {
                    this.loadingActive = false;
                    this.error = true;
                    this.errorMsg ="Please fill email and password!";
                    setTimeout(()=>{
                        this.error = false;
                    }, 3000);
                    return;
                }

            },

            closeModal(){
                this.modalActive = !this.modalActive;
            },

        }
    }

</script>

<style scoped>
    input, button, .card{
        border-radius: 20px !important;
    }
</style>